import React from 'react'
import {NavLink} from 'react-router-dom'
import './SideDrawer.css'

const sideDrawer = props => {
   let drawerClasses = 'side-drawer';
   if(props.show) {
   drawerClasses = 'side-drawer open';
}
  return (
    <nav className={drawerClasses}>
      <ul>
        <li>
          <NavLink exact to="/" onClick={props.backdrop}>Domov </NavLink>
        </li>
        <li>
          <NavLink exact to ="/solanja" onClick={props.backdrop}>Šolanja </NavLink>
        </li>
        <li>
           <NavLink exact to="/program" onClick={props.backdrop}>Program </NavLink>
        </li>
        <li>
           <NavLink exact to="/About" onClick={props.backdrop}>O nas </NavLink>
        </li>
        <li>
           <NavLink exact to="/contact" onClick={props.backdrop}>Kontakt </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default sideDrawer
