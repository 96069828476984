import React from 'react'
import Program from './Program.css'
import AnimatedPart from './animatedPart';
import ReactDOM from 'react-dom'
class program extends React.Component {
render() {
return (
  <div id="changingProgram" >
     <div class="changingProgramImg">
<div id="centeredProgramImg">
</div>
</div>
<div class="changingProgramText">
<div class="centeredInnerBody">
<div class="programTitleSubtitle">
<h2>Klaes produkcijska oprema</h2>
<h1>Izberite za vas prilagojene programske rešitve</h1>
</div>
<div class="programIntroText">
<div id="programIntroLeft">
<p>Vsem podjetjem ponujamo širok spekter prilagojenih rešitev za industrijo oken, vrat, fasad in zimskih vrtov. Od prilagodljivih programskih rešitev za izdelavo konstrukcijskih elementov do
globoko integriranih rešitev za načrtovanje zalog (ERP) v industrijski produkciji.</p>
</div>
<div id="programIntroRight">
<p>Stalen programski razvoj, dolgoletne izkušnje, visok kvalitetni nadzor in nad vsem edinstveno sodelovanje z dobavno industrijo, omogoča ključno prednost v vsaki aplikativni točki okenske industije.</p>
</div>
</div>
<AnimatedPart  />
</div>
</div>
</div>
);
}
}
export default program
