
import React from 'react'

class Komfort extends React.Component{
render(){
      return (
<div class="centeredInnerBody">
<div class="potek">
<h2 class="cntr">Komfortno šolanje</h2>
<ul>
<li>Časovni okvir šolanje ni omejen in je odvisen od želja podjetja.</li>
<li>Program se inštalira na odgovarjajočih mestih, kar je odvisno od števila licenc na vseh računalnikih in na serverju.  Testni podatki se nasnamejo v sistem kot tudi podatki od posameznih dobaviteljev (Če so na razpolago).</li>
<li>Posredovan Vam je celoten izčrpen pregled funkcionalnosti celotnega programa. Podatki se med šolanjem nastavijo na posebnosti vašega podjetja. Na osnovi podatkov iz prakse se dela primere od zajemanja naročila oz. ponudbe do izdelave računa.</li>
</ul>
</div>
<div class="cilj">
<h3 class="cntr">Cilj šolanja</h3>
<p>Po šolanju boste lahko samostojno izdelovali projekte. Vsi podatki so nastavljeni specifično na vaše podjetje. Komfortno šolanje  je primerno za podjetja, ki bi rada kar v najkrajšem času izkoristila vse prednosti Klaes programa. Klaes prevzame torej vse potrebne ukrepe za nastavitve, ki so nastavljene na osnovi potreb vašega podjetja.</p>
</div>
</div>
      );
    }
}

export default Komfort
