import React from 'react'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import './Toolbar.css'
import { NavLink } from 'react-router-dom'
import Logo from './LogoWhite.svg'
import KlaesLogo from './KlaesLogo.svg'

const toolbar = props => (
<header className="toolbar">
    <nav className="toolbar__navigation">
      <div>
 <DrawerToggleButton click={props.drawerClickHandler} />
</div>
      <div className="toolbar__logo">
        <a href="/"><img src={KlaesLogo}/></a>
        <a href="/" class="bpp"><img src={Logo}/></a>
      </div>
      <div className="spacer" />
      <div className="toolbar_navigation-items">
        <ul>
          <li class="navLink"><NavLink exact to="/">Domov</NavLink></li>
          <li class="navLink"><NavLink exact to="/solanja">Šolanja</NavLink></li>
          <li class="navLink"><NavLink exact to="/program">Program</NavLink></li>
          <li class="navLink"><NavLink exact to="/About">O nas</NavLink></li>
          <li class="navLink"><NavLink exact to="/contact">Kontakt</NavLink></li>
        </ul>
      </div>
    </nav>
  </header>
)
export default toolbar
