import React from 'react';
import Fade from 'react-reveal/Fade';
import Premium from './Premium.svg'
import Professional from './Professional.svg'
import Vario from './Vario.svg'
import Trade from './Trade.svg'
import D3 from './3D.svg'
class AnimatedPart extends React.Component {
render() {
return (
<span class="animationz">
<div class="animatedProgram">
<Fade left>
<div class="animatedImg">
<a href="https://www.klaes.de/en-klaes-premium.html"><img class="imgP" src={Premium} /></a>
</div>
</Fade>
<Fade right>
<div class="animatedTextOuter rImg">
<div class="animatedText">
<a href="https://www.klaes.de/en-klaes-premium.html" class="noUnder"><h4 class="blueh4">Klaes premium</h4></a>
<p>Najboljša ERP-rešitev za vodenje zaloge v vseh sektorjih vašega podjetja <br /><br />
Klaes premium je naš moćnejši paket za večja podjetja z avtomatizirano produkcijo ki poskrbi za najvećji volumen naročil. Perfektna ergonomija in maksimalna fleksibilnost
vam zagotavljata trajno rast in profit, poleg tega pa z njim dobite najvećji njivo podpore in storitev.
</p>
<a href="https://www.klaes.de/en-klaes-premium.html" class="noUnder">Več ></a>
</div>
</div>
</Fade>
</div>
<div class="animatedProgram">
<Fade left>
<div class="animatedImg">
<a href="https://www.klaes.de/en-klaes-professional.html"><img class="imgP" src={Professional}/></a>
</div>
</Fade>
<Fade right>
<div class="animatedTextOuter">
<div class="animatedText">
<a href="https://www.klaes.de/en-klaes-professional.html" class="noUnder">
<h4 class="blueh4">Klaes professional</h4></a>
<p>Za podjetja z avtomatizirano produkcijo <br /><br />
Klaes professional vam ponuja dostop do večih modulov z pomočjo katerih lahko uspešno obvladate kompleksne vsakodnevne poslovne izive, poleg tega vsebuje tudi popolno podporo.</p>
<a href="https://www.klaes.de/en-klaes-professional.html" class="noUnder">Več ></a>
</div>
</div>
</Fade>
</div>
<div class="animatedProgram">
<Fade left>
<div class="animatedImg">
<a href="https://www.klaes.de/en-klaes-vario.html"><img class="imgP" src={Vario}/></a>
</div>
</Fade>
<Fade right>
<div class="animatedTextOuter">
<div class="animatedText">
<a href="https://www.klaes.de/en-klaes-vario.html" class="noUnder">
<h4 class="blueh4">Klaes vario</h4></a>
<p>Prilagodljiv količini vaših naročil <br /><br />
Ima prilagodljivo ceno glede na količino naročil in vam ponuja storitve katere zares potrebujete. Klaes vario - ekonomska rešitev za vsako podjetje v okenski industriji.</p>
<a href="https://www.klaes.de/en-klaes-vario.html" class="noUnder">Več ></a>
</div>
</div>
</Fade>
</div>
<div class="animatedProgram">
<Fade left>
<div class="animatedImg">
<a href="https://www.klaes.de/en-klaes-trade.html"><img class="imgP" src={Trade}/></a>
</div>
</Fade>
<Fade right>
<div class="animatedTextOuter">
<div class="animatedText">
<a href="https://www.klaes.de/en-klaes-trade.html" class="noUnder">
<h4 class="blueh4">Klaes trade</h4></a>
<p>Idealna rešitev za preprodajalce <br /><br />
V samo nekaj korakih boste lahko oblikovali vizualno privlaćno ponudbo, katera bo dvignila njivo vaših storitev.
Okenska, vratna, storitvena ali popravilna dela in celo vaši ćlanki bodo predstavljeni optimalno.
</p>
<a href="https://www.klaes.de/en-klaes-trade.html" class="noUnder">Več ></a>
</div>
</div>
</Fade>
</div>

<div class="animatedProgram">
<Fade left>
<div class="animatedImg">
<a href="https://www.klaes.de/en-klaes-3d.html"><img class="imgP" src={D3}/></a>
</div>
</Fade>
<Fade right>
<div class="animatedTextOuter">
<div class="animatedText">
<a href="https://www.klaes.de/en-klaes-3d.html" class="noUnder">
<h4 class="blueh4">Klaes 3D</h4></a>
<p>Rešitev za zimske vrtove in fasadno gradnjo <br /><br />
Klaes 3D vam ponuja vsa orodja ki jih bote kadarkoli potrebovali za gradnjo fasad in zimskih vrtov, njihovo profesionalno predstavitev ter racionalno naćrtovanje in produkcijo.
</p>
<a href="https://www.klaes.de/en-klaes-3d.html" class="noUnder">Več ></a>
</div>
</div>
</Fade>
</div>
</span>
);
}
}
export default AnimatedPart
