
import React, { Component } from 'react';
import { HashRouter as Router, Route, Link } from 'react-router-dom';
import './App.css';
import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import Home from './components/Page/Home/Home';
import Contact from './components/Page/Contact/Contact';
import Lecturing from './components/Page/Lecturing/Lecturing';
import Program from './components/Page/Program/Program';
import About from './components/Page/About/About';
import Info from './components/Info/Info';
import Article1 from './components/Page/Articles/Article1';
import Article2 from './components/Page/Articles/Article2'; 
import Article3 from './components/Page/Articles/Article3'; 
import Article4 from './components/Page/Articles/Article4'; 

class App extends React.Component {
state = {
    sideDrawerOpen: false,
    news: this.props.appData
};

drawerToggleClickHandler = () => {
this.setState((prevState) => {
 return {sideDrawerOpen: !prevState.sideDrawerOpen};
});
};
backdropClickHandler = () => {
this.setState({sideDrawerOpen: false});
};
  render() {
   let backdrop;
 if(this.state.sideDrawerOpen) {
 backdrop = <Backdrop click={this.backdropClickHandler} />
}
console.log = console.warn = console.error = () => {};
    return (
<Router>
     <div id = "toolbar">
      <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
      <SideDrawer show={this.state.sideDrawerOpen} backdrop={this.backdropClickHandler} />
      {backdrop}
</div>
      <main>
      <switch>
      <Route exact path='/' render={()=><Home news={this.state.news}/>} />
      <Route path="/contact" component={Contact} />
      <Route path="/solanja" component={Lecturing} />
      <Route path="/program" component={Program} />
      <Route path="/about" component={About} />
      <Route path="/article1" component={Article1} />
      <Route path="/article2" component={Article2} />
      <Route path="/article3" component={Article3} /> 
      <Route path="/article4" component={Article4} /> 
      </switch>
</main>
<Info />
</Router>
    );
  }
}

export default App;
