
import React from 'react'

class Standardno extends React.Component{
render(){
      return (
<div class="centeredInnerBody">
<div class="potek">
<h1 class="cntr">Standardno šolanje</h1>
<ul>
<li>Čas trajanja šolanja je dva dni za COMPACT in program posrednikov in tri dni za  PROFESSIONAL-PROGRAM  ( razem za module materialnega poslovanja, planiranja kapacitet in programskega orodja za ON LINE)</li>
<li>Program se inštalira na enem delovnem mestu ali na serverju. Inštalacija se razloži ter kopirajo se testni podatki. Poleg tega se inštalirajo podatki profilov, stekla in okovja, če so podatki zahtevani in dostavljeni stranki od zgoraj omenjenih dobaviteljev.</li>
<li>Po inštalaciji programa se poda splošen pregled o funkcionalnosti programa. Nato se razložijo posamezni moduli programa in vnesejo se podatki za delo z moduli. Na osnovi tipov oken se dela primere iz prakse. Cilj je obvladovanje izdelave projekta od zajemanja naročila oz ponudbe pa do izdelave računa.</li>
</ul>
</div>
<div class="cilj">
<h3 class="cntr">Cilj šolanja</h3>
<p>Po standardnem šolanju lahko izdelujete ponudbe za standardna okna in obdelovati projekte. Usposobljeni ste tudi za za spreminjanje podatkov za kalkulacije, konstrukcijo in za prezentacije. Standardno šolanje je torej namenjeno za vsa podjetja, ki hočejo takoj začeti z delom s programom ter so zmožni samostojno spreminjati specifične nastavitve za svoje podjetje.</p>
</div>
</div>
      );
    }
}

export default Standardno
