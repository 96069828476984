import React from 'react';
import style from './Article.css';

const Article2 = props => (
<div class="articlesOuter">
<h2 class="articlesTitle">Spletna trgovina za okna in vrata</h2>
<p class="articlesText">Internet je spremenil svet! Skoraj vsak izdelek lahko dandanašnji naročite prek interneta. Zakaj torej ne tudi okna in vrata? Predstavljajte si da si lahko vaši posredniki in stranke vaše izdelke preprosto naročijo v vaši spletni trgovini. S tem ne boste dosegli samo boljše prepoznavnosti ampak tudi z novimi strankami povečali dobiček podjetja!</p>
</div>
)
export default Article2;
