import React from 'react'
import ReactDOM from 'react-dom'
import Lecturing from './Lecturing.css'
import Sistemsko from './Sistemsko'
import Standardno from './Standardno'
import Komfort from './Komfort'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAtom } from '@fortawesome/free-solid-svg-icons'
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons'
import { faMugHot } from '@fortawesome/free-solid-svg-icons'

class lecturing extends React.Component {

state = {
     sistemske: [
        { id: '1', title: 'Sistemsko' }
     ],
     standardne: [
        { id: '1', title: 'Standardno'}
     ],

     komfortne: [
         {id: '1', title: 'Komfortno'}
     ],
        displaySistemske: false,
        displayStandardne: false,
        displayKomfortne: false
}

displaySistemske = () => {
 this.setState({displaySistemske: !this.state.displaySistemske})
let sistemske = this.state.displaySistemske;
if(sistemske == false) {
this.setState({
          displayKomfortne: false, displayStandardne: false
})
}
}
displayStandardne = () => {
    this.setState({displayStandardne: !this.state.displayStandardne})
let standardne = this.state.displayStandardne;
if(standardne == false) {
this.setState({displayKomfortne: false, displaySistemske: false})
}
}
displayKomfortne = () => {
    this.setState({
        displayKomfortne: !this.state.displayKomfortne
    })
let komfortne = this.state.displayKomfortne;
if(komfortne == false) {
this.setState({
          displayStandardne: false, displaySistemske: false
})
}
}
render() {
let sistemski = null;
let standardni = null;
let komfortni = null;
if ( this.state.displaySistemske ) {
     sistemski = (
     <Sistemsko /> );
}
if ( this.state.displayStandardne) {
     standardni = (
     <Standardno />
     )}
if (this.state.displayKomfortne) {
     komfortni = (
     <Komfort />
     )}
return(
<div id="changingLecturing">
     <div class="changingLecturingImg">
<div class="programTitleSubtitle aboutTitleSubtitle">
<h2>Šolanja</h2>
<h1>Mi organiziramo podjetja</h1>
</div>
</div>
<div class="changingLecturingText">
<div class="lecturingOptions">
<div class="innerOption" onClick={this.displaySistemske}>
<div class="icn"><FontAwesomeIcon icon={faAtom} /></div>
<h2 class="solanjeh2">Sistemsko šolanje</h2>
<h4 class="solanjeh4">Več</h4>
</div>
<div class="innerOption" onClick={this.displayStandardne}>
<div class="icn"><FontAwesomeIcon icon={faChalkboardTeacher} /></div>
<h2 class="solanjeh2">Standardno šolanje</h2>
<h4 class="solanjeh4">Več</h4>
</div>
<div class="innerOption" onClick={this.displayKomfortne}>
<div class="icn"><FontAwesomeIcon icon={faMugHot} /></div>
<h2 class="solanjeh2">Komfortno šolanje</h2>
<h4 class="solanjeh4">Več</h4>
</div>
</div>
<div class="renderedOptions">
{sistemski}
{standardni}
{komfortni}
</div>
</div>
</div>
);
}
}

export default lecturing
