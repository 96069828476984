import React from 'react'
import Contact from './Contact.css'
import * as emailjs from 'emailjs-com'
import $ from 'jquery'

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }


    componentDidMount(){

//var myform = React.findDOMNode(this.refs.formRef);
var myform = $("form#contactForm");
myform.submit(function(event){
	event.preventDefault();

  // Change to your service ID, or keep using the default service
  var service_id = "default_service";
  var template_id = "Klaes";

  myform.find("button").text("Pošiljamo...");
  emailjs.sendForm(service_id,template_id,myform[0],'user_NLqvg4MUqAka7S9jTfioF')
  	.then(function(){ 
    	alert("Poslano!");
       myform.find("button").text("Send");
    }, function(err) {
       alert("Ni poslano \r\n Response:\n " + JSON.stringify(err));
       myform.find("button").text("Send");
    });
  return false;
});}
    render(){
    	return(
  <div id="changingContact">
     <div class="ChangingContactImg">
     <div class="programTitleSubtitle aboutTitleSubtitle">
     <h2>Iščete nekaj posebnega?</h2>
     <h1>Z veseljem se vam osebno posvetimo</h1>
</div>
</div>
<div class="changingContactText">
<div id="alignText">
<div id="innerText">
<p>B.P. d.o.o. <br />
Lepodvorska 26 <br />
1000 Ljubljana <br />
E: Peter-Breznik@siol.com; pbreznik@klaes.com <br />
M: +386 (0)41 639 188 <br />
T: +386 (0) 1 514 20 72 <br />
F: +386 (0) 1 514 20 72 <br />
</p>
</div>
</div>
<div id="alignForm">
<div id="innerForm">
<form id="contactForm">
<label>Ime</label><br />
<input type="text" name="ime" class="fInpt" /> <br />
<label>Priimek</label> <br />
<input type="text" name="priimek" class="fInpt"/> <br />
<label>Tel. št./email</label> <br />
<input type="text" name="stEmail" class="fInpt" /> <br />
<label>Kdaj želite da vas kontaktiramo?</label> <br />
<input type="text" name="ura" class="fInpt"/> <br />
<br />
<button>
Pošlji
</button>
</form>
</div>
</div>
</div>
</div>
);
};
};
export default ContactPage
