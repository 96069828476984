import React from 'react';
import style from './Article.css';

const Article4 = props => (
<div class="articlesOuter">
<h2 class="articlesTitle">Klaes Info Manager</h2>
<p class="articlesText">Samo tisti, ki ima najnovejše informacije o projektih, lahko zagotavlja hitre in kakovostne postopke v svojem podjetju. Z Klaes Info Manager-jem skrbite za dobro in hitro komunikacijo med sodelavci in prihranite z avtomatiziranimi procesi ogromno časa, napak in denarja. Pomaga vam organizirati in optimirati komunikacijske procese v podjetju. V Jurnalu lahko shranite vi in vaši sodelavci zapiske pogovorov, dodeljujete naloge, novice in obvestila sodelavcem.</p>
</div>
)
export default Article4;
