import React from 'react';
import style from './Article.css';

const Article1 = props => (
<div class="articlesOuter">
<h2 class="articlesTitle">Vaš kakovostni Online-Service za strojno in programsko opremo</h2>
<p class="articlesText">Cloud-Computing pomeni sodobno in varno IT strategijo. Pri tem se strojno in programsko opremo premesti iz podjetja v oddaljeni računalniški center(oblak). To pomeni, da ne rabite več Klaes serverja v podjetju na katerem je inštaliran program ampak namesto tega na eksternem serverju. Vaši sodelavci še naprej delajo na lokalnih računalnikih na vašem novem serverju. Kako to deluje? </p> 
<p class="articlesText">V bistvu se sestoji vaša mreža še naprej iz serverja in delovnih mest z razliko, da se server ne nahaja v vašem podjetju ampak v računalniškem centru v Nordrhein-Westfalen.To se dogaja skozi popolnoma varno VPN povezavo. Tudi ostalo programsko opremo ki ste jo imeli inštalirano lokalno je lahko varno inštalirate na sreverju v računalniškem centru. </p>
</div>
)
export default Article1;
