import React from 'react'

class Sistemsko extends React.Component{
render(){
      return (
<div class="centeredInnerBody">
<div class="potek">
<h1 class="cntr">Sistemsko šolanje</h1>
<ul>
<li>Osnovna navodila v enem dnevu</li>
<li>Program se inštalira na enem delovnem mestu oz.na serverju. Razložijo se posamezni koraki inštalacije ter se nato kopirajo testni podatki, da se lahko takoj začne z delom na programu.</li>
<li>Inštalirani podatki zajemajo profile, stekla, okovje in ostale dodatne artikle. Podatki so vnešeni kot testni podatki in se seveda razume, da niso pripravljeni specifično za posamezno podjetje.</li>
<li>Sistemsko šolanje je primerno za izkušene tehnike, ki so že delali s programi za izdelavo oken. Razpolagajo naj s predznanjem za samostojen vnos specifičnih podatkov podjetja oz. za prilagoditev testnih primerov in za prilagoditev list izpisov.</li>
</ul>
</div>
<div class="cilj">
<h3 class="cntr">Cilj šolanja</h3>
<p>Po sistemskem šolanju se spozna delo s programskim orodjem Klaes.  Spozna se osnovne možnosti za delo s programom. Sami lahko konstririrate okna v Kles programu s pomočjo testnih podatkov in jih izpisujete.</p>
</div>
</div>
      );
    }
}

export default Sistemsko
