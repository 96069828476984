import React from 'react'
import Lecturing from './Info.css'
import Pro from './Pro.pdf'
import Vario from './Vario.pdf'
const info  = props => (
  <div id="info">
<div class="downloads">
<div class="download">
<a href = {Pro} download="Klaes pro">Klaes professional info</a>
</div>
<div class="download">
<a href = {Vario} download="Klaes vario">Klaes vario info</a>
</div>
</div>
<div id="Bprogramming">
<a href="https://bprogramming.com/">Made by B.Programming</a>
</div>
</div>
)

export default info
