import React from 'react';
import style from './Article.css';

const Article3 = props => (
<div class="articlesOuter">
<h2 class="articlesTitle">Novi dodatni servis za vaše stranke </h2>
<p class="articlesText">Naredite korak naprej in nudite vašim posrednikom in monterjem transparentni pogled o stanju njihovih naročil! Z zahtevanim geslom se vaši monterji in posredniki projavijo na vaš Webserver in vidijo v katerem statusu se nahaja njihovo naročilo.</p>
<p class="articlesText">Tu lahko vidijo ali se je že pričela izdelava v proizvodnji an ali je bilo njihovo naročilo že proizvedeno in dobavljeno. Ti niso vezani na fiksne uradne ure podjetja ampak lahko preverijo stanje naročil 24 ur na dan. </p>
</div>
)
export default Article3;
