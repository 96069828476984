import React from 'react'
import About from './About.css'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom'
const about  = props => (
<div id="changingAbout">
 <div class="changingAboutImg">

<div class="programTitleSubtitle aboutTitleSubtitle">
<h2>Mi organiziramo podjetja</h2>
<h1>vaš uspeh je tudi naš uspeh</h1>
</div>
</div>
 <div id="changingAboutText">

<div class="centeredInnerBody">
<div class="programIntroText noPad">

<div class="About-left">
<p>Kar se je v Rosenheimu začelo leta 1983 z enim človekom se je v zadnjih 30 ih letih razvilo v internacionalno podjetje - ustvarjeno v nemčiji.
</p> <br />
<p>Trajen programski razvoj, dolgoletne izkušnje, in implementacija, visok kvalitetni nadzor in edinstveno sodelovanje z dobavno industrijo
vam zagotavlja ključno prednost pred konkurenco.
Neglede na to ali ste posredniki, večje podjetje ali proizvodnja vam Klaes ponuja več kot samo inovativne programske rešitve.
</p>
</div>

<div class="About-right">
<p>Mi ponujamo posebno prilagojene rešitve za podjetja vseh velikosti. Od nadzorovanih in fleksibilnih rešitev za prodajo komponent
do visoko integriranih ERP rešitev za industrijsko produkcijo.
</p><br />
<p>Že nekaj let nazaj sta Miriam Berzen in Lars Klaes uspešno prevzela vodstvo. Z sinovom in hčerko znanega ustanovitelja po imenu Horst Klaes, je
zagotovljena naša tržna prisotnost in prilagojene rešitve za naslednjo generacijo strank.
</p>
</div>
</div>
</div>
<div class="apointment">
<div class="innerApointment">
<p>Vas zanima več o naših rešitvah? Z veseljem se vam osebno svetujemo</p>
<Link exact to="contact">Rezervirajte osebno svetovanje</Link>
</div>
</div>

</div>
<div class="centeredInnerBody">
<div class="facts">
<h2>Več kot besede...</h2>
<h4 class="blueh4">Naša dejstva</h4>
</div>
<div class="counters">
<div id="leftCounter">
<div class="counterAlign">
<CountUp
 end={240}
 duration={5}
/>
</div>
<div class="textCounterAlign">
<h3 class="blueh3 thin">zaposlenih</h3>
<h4 class="blueh4 thin">v 27 državah</h4>
</div></div>
<div id="centerCounter">
<div class="counterAlign">
<CountUp
 end={7000}
 duration={5}
/>
</div>
<div class="textCounterAlign">
<h3 class="blueh3 thin">strank</h3>
<h4 class="blueh4 thin">v več kot 50 državah</h4>
</div></div>
<div id="rightCounter">
<div class="counterAlign">
<CountUp
 end={27}
 duration={5}
/>
</div>
<div class="textCounterAlign">
<h3 class="blueh3 thin">jezikovnih verzij</h3>
<h4 class="blueh4 thin">programskih rešitev na voljo</h4>
</div>
</div>
</div>
</div>

</div>
);

export default about
