import React from 'react'
import Home from './Home.css'
import Fade from 'react-reveal/Fade'
import HomeImg from './HomeImg.svg'
import { Link } from 'react-router-dom'

const home = props => (
  <div id="changingHome">
<div id="changingHomeImg">
<div class="animatedProgram">
<Fade left>
<div class="animatedImg">
<Link exact to="/solanja"><img id="hhero"src={HomeImg} /></Link>
</div>
</Fade>
<Fade right>
<div class="animatedTextOuter">
<div class="animatedText">
<h4 class="blueh4">Inovativne programske rešitve za idustrijo oken, vrat, fasad in zimskih vrtov</h4>
<p>od nadzorovanih in fleksibilnih rešitev za trgovce konstrukcijskih komponent, do globoko integriranih ERP rešitev za industrijsko produkcijo</p>
<br />
<Link exact to="/program" class="hvec noUnder">Več ></Link>
</div>
</div>
</Fade>
</div>

</div>
<div class="changingHomeText">
<div class="news">
<article class="first">
<Link exact to="/article1">
<h2>{JSON.parse(props.news).first.title}</h2>
<p>{JSON.parse(props.news).first.short}</p>
<br />
<p>{JSON.parse(props.news).first.date}</p>
</Link>
</article>
<article class="second">
<Link exact to="/article2">
<h2>{JSON.parse(props.news).second.title}</h2>
<p>{JSON.parse(props.news).second.short}</p>
<br />
<p>{JSON.parse(props.news).second.date}</p>
</Link>
</article>
<article class="third">
<Link exact to="/article3">
<h2>{JSON.parse(props.news).third.title}</h2>
<p>{JSON.parse(props.news).third.short}</p>
<br />
<p>{JSON.parse(props.news).third.date}</p>
</Link>
</article>
<article class="fourth">
<Link exact to="/article4">
<h2>{JSON.parse(props.news).fourth.title}</h2>
<p>{JSON.parse(props.news).fourth.short}</p>
<br />
<p>{JSON.parse(props.news).fourth.date}</p>
</Link>
</article>
</div>
<a href="https://medium.com/@klaesbp" id="VseNovice">Vse novice< /a>
<div class="centeredInnerBody pdingTop">
<div class="programTitleSubtitle">
<h2>Okna. Fasade. Zimski vrtovi.</h2>
<h1>Programske rešitve za podjetja vseh velikosti</h1>
</div>
</div>
<div class="centeredHomeImage">
<Fade top>
<div id="HomePackagesImage">
</div>
</Fade>
</div>
<div class="centeredInnerBody">
<div class="programIntroText">
<div id="HomeIntroLeft">
<h4>Klaes produkcijske linije</h4>
<p>Za vse procese v podjetjih okenske konstrukcije vam ponujamo prilagojene rešitve, neglede na velikost podjetja. 
Mi dobro razumemo te procese, zato boste od prodajnega oddelka skozi procesiranje naročil, načrtovanje, izvedbo, dostavo, računovodstvo in administracijo našli 
prava orodja za vsa podjetja.</p>
<Link exact to="/program">Najdi produkcijsko linijo</Link>
</div>
<div id="HomeIntroRight">
<h4>Klaes Individualne rešitve</h4>
<p>Za optimizacijo in organizacijo vašega poteka dela vam poleg produkcijske linije ponujamo tudi individualne rešitve za razne potrebe vašega podjetja.
Pozorni pa smo tako na organizacijo, načrtovanje in produkcijo kot tudi storitve in standardizacijo na medmrežnem okolju.</p>
<a href="https://www.klaes.de/en-individual-solutions.html">Najdi produkcijsko linijo</a>
</div>

</div>

</div>

</div>
</div>
)

export default home
